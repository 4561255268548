/* YourComponent.css */


.graph-portion {
   margin-bottom: 10px;
  }

.graph{
    flex: 1; /* Distribute available space equally among the graphs */
    margin: 5px; /* Add spacing between graphs if needed */
    height: 200px; /* Add borders or other styles as needed */
}


.graph canvas {
    /* This targets the canvas element inside the chart component */
    overflow: hidden;
  }