.App {
    font-family: sans-serif;
    text-align: center;
  }
  

.dashboard button {
    position: absolute;
    right: 20px !important;
    top:20px !important;
  }